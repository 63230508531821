#heading {
  position: absolute;
  width: 50%;
  font-size: 50px;
  text-align: start;
  color: white;
  font-weight: lighter;
}
#nav_img {
  background-position: center;
  background-size: cover;
  padding-bottom: 400px;
  padding-top: 100px;
  width: 100%;
  height: 45pc;
}
#nav_heading {
  position: absolute;
  width: 50%;
  font-size: 50px;
  text-align: start;
  color: white;
  font-weight: lighter;
}
#sub_heading {
  position: absolute;
  width: 50%;
  top: 17pc;
  font-size: 20px;
  text-align: start;
  color: white;
  font-weight: lighter;
}
#firstblockimg {
  background-position: center;
  background-size: cover;
  padding-bottom: 400px;
  padding-top: 100px;
  width: 100%;
  height: 40pc;
}
.slick-next {
  display: none !important;
}
.card_data {
  width: 20pc;
  margin: auto auto;
}
@media screen and (max-width: 895px) {
  #firstblockimg {
    margin-top: 5pc;
  }
}

@media screen and (max-width: 895px) {
  #firstblockimg {
    background-position: 70%;
    background-size: cover;
    padding-bottom: 0px;
    padding-top: 120px;
    margin-right: auto;
    width: 100%;
    height: auto;
  }
  #nav_heading {
    width: 90%;
  }
  #sub_heading {
    width: 80%;
  }
}
#ListDiv {
  width: "100%";
  display: flex;
  justify-content: flex;
  align-items: center;
  padding-left: 10pc;
}
#watchnow {
  position: absolute;
  padding: 10px 20px;
  margin: 15pc 0%;
}
#hedingholder {
  width: "50%";
  padding: "80px 50px";
  text-align: "start";
}
@media screen and (max-width: 1224px) {
  #heading {
    font-size: 44px;
  }
  #sub_heading {
    font-size: 20px;
  }
}
@media screen and (max-width: 895px) {
  #heading {
    font-size: 30px;
    padding: 0px;
    margin-top: -103px;
  }
  #watchnow {
    margin: 0;
    margin-top: 10px;
  }
  #ListDiv {
    padding-left: 100px;
  }
}
@media screen and (max-width: 543px) {
  #heading {
    font-size: 25px;
    padding: 0px;
    margin-top: -103px;
  }
  #watchnow {
    padding: 5px 10px;
  }
}
@media screen and (max-width: 453px) {
  #heading {
    margin-top: -150px;
  }
  #continer {
    padding: 0;
  }
  .card_data {
    width: 100%;
  }
}
@media screen and (max-width: 404px) {
  #nav_img {
    height: 55pc;
  }
  #sub_heading {
    top: 22pc;
  }
}

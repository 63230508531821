.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: inherit;
  height: 100%;
  background-color: #f16071;
  overflow-x: hidden;
  padding-top: 20px;
}

.sidebar-header {
  padding: 10px;
  text-align: center;
}

.sidebar-links {
  text-align: center;
}

.sidebar-links ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.sidebar-links li {
  padding: 8px 0px;
  cursor: pointer;
}

.sidebar-links button {
  margin-left: 5px;
  padding: 0;
}

.sidebar-options {
  padding-left: 50px;
}

.Contain2{
    display: flex;
    margin-top: 2pc;
    margin-bottom: 2pc;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    width: 55%;
    gap:20px

}
.Contain3{
    display:flex;
    width:fit-content;
    margin: auto auto;
    margin-top: 2pc;
    margin-bottom: 2pc;
    gap: 20px;
}

@media screen and (max-width: 899px){
    .Contain2{
        display: grid;
        width: auto;
    }
    .Contain3{
        display:grid;
    }
    
}